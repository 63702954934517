<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI智能商业计划书生成" />
                        <ai_select selectTitle="项目类型" :select_list="style_list" @getSelectVal="get_style" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">项目名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.product_name" placeholder="输入您预制作的项目名称"></el-input>
                            </div>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
export default {
    name: '',
    created() { },
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            form: {
                type: '',
                product_style: '',
                product_name: '',
            },
            style_list: [
                {
                    id: '零售',
                    name: '零售'
                },
                {
                    id: '餐饮',
                    name: '餐饮'
                },
                {
                    id: '互联网',
                    name: '互联网'
                },
                {
                    id: '服务',
                    name: '服务'
                },
                {
                    id: '制造',
                    name: '制造'
                },
                {
                    id: '农业',
                    name: '农业'
                },
                {
                    id: '建筑/房地产',
                    name: '建筑/房地产'
                },
                {
                    id: '金融',
                    name: '金融'
                },
                {
                    id: '文化/娱乐',
                    name: '文化/娱乐'
                },
                {
                    id: '教育',
                    name: '教育'
                },
                {
                    id: '医疗',
                    name: '医疗'
                },
                {
                    id: '环保/清洁能源',
                    name: '环保/清洁能源'
                },
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_style(val) {
            this.form.product_style = val
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80,
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80,
                    });
                }
            );
        },
        FileData() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/create_business', {
                product_style: this.form.product_style,
                product_name: this.form.product_name,
                mobile: this.$user_info.mobile,
            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>